<template>
	<div v-dialogdrag>
	<el-dialog title="采购详情" v-model="centerDialogVisiblep" width="40%" center :close-on-click-modal = "false" @close='closecallinfop'>
		<div class="ucPanel" style="height: 668px;">
			<el-tabs v-model="activeName" @tab-click="handleClick" >
			    <el-tab-pane label="&nbsp;&nbsp;&nbsp;&nbsp;合同详情&nbsp;&nbsp;&nbsp;&nbsp;" name="first" >
					<div class="ucPanel bgGrayBox">
						<div class="ucForm ucForm-lump auto-d3 nameLong" >
							<div class="ucFrom-row" style="margin-bottom: 20px;">
							<div class="ucForm-group-ad">
								<div class="ucForm-group">
									<label class="ucForm-label">合同类型</label>
									<select v-model="ctype" class="ucForm-control" @change="htlx()" :disabled="true">
										<option v-for="ctype in ctypes" v-bind:key="ctype.value" :value="ctype.value">
											{{ctype.ctype}}
										</option>
									</select>
								</div>
							</div>	
							<div class="ucForm-group-ad">		
								<div class="ucForm-group">
									<label class="ucForm-label">合同名称</label>
									<input name="wholesalePrice" type="text" v-model="cname" :disabled="true" class="ucForm-control" >
								</div>
							</div>
							<div class="ucForm-group-ad">		
								<div class="ucForm-group">
									<label class="ucForm-label">合同编号</label>
									<input name="wholesalePrice" type="text" v-model="cno" :disabled="true" class="ucForm-control" >
								</div>
							</div>
							<div class="ucForm-group-ad">			
								<div class="ucForm-group">
									<label class="ucForm-label">签订部门</label>
									<select v-model="gid" class="ucForm-control" :disabled="true">
										<option v-for="option in departments" v-bind:key="option.id" :value="option.id" >
											{{option.name}}
										</option>
									</select>
								</div>
							</div>
							<div class="ucForm-group-ad">		
								<div class="ucForm-group">
									<label class="ucForm-label">申请时间</label>
									<el-config-provider :locale="locale">
										<el-date-picker v-model="applyDate" type="date" :disabled="true"></el-date-picker>
									</el-config-provider>
								</div>
							</div>
							<div class="ucForm-group-ad">		
								<div class="ucForm-group">
									<label class="ucForm-label">签订时间</label>
									<el-config-provider :locale="locale">
										<el-date-picker v-model="signDate" type="date" :disabled="true"></el-date-picker>
									</el-config-provider>
								</div>
							</div>
							<div class="ucForm-group-ad">		
								<div class="ucForm-group">
									<label class="ucForm-label">合同金额/元</label>
									<input name="wholesalePrice" type="number" v-model="amount" :disabled="true" class="ucForm-control">
								</div>
							</div>
							<div class="ucForm-group-ad">		
								<div class="ucForm-group">
									<label class="ucForm-label">项目类别</label>
									<select v-model="xmlb" class="ucForm-control" :disabled="true">
										<option v-for="option in xmlbs" v-bind:key = "option.id" :value="option.id">
											{{option.name}}
										</option>
									</select>
								</div>
							</div>
							<div class="ucForm-group-ad">		
								<div class="ucForm-group">
									<label class="ucForm-label">专业</label>
									<select v-model="zy" class="ucForm-control" :disabled="true">
										<option v-for="option in zys" v-bind:key = "option.id" :value="option.id">
											{{option.name}}
										</option>
									</select>
								</div>
							</div>
							<div class="ucForm-group-ad" v-if="fwht">		
								<div class="ucForm-group">
									<label class="ucForm-label">项目负责人</label>
									<input name="wholesalePrice" type="number" v-model="xmfzr"  :disabled="true" class="ucForm-control">
								</div>
							</div>
							<div class="ucForm-group-ad" v-if="fwht">		
								<div class="ucForm-group">
									<label class="ucForm-label" >项目地点</label>
									<input name="wholesalePrice" type="text" v-model="xmdd" :disabled="true" class="ucForm-control">
								</div>
							</div>
							<div class="ucForm-group-ad" v-if="fwht">		
								<div class="ucForm-group">
									<label class="ucForm-label" >工程投资</label>
									<input name="wholesalePrice" type="text" v-model="gctz" :disabled="true" class="ucForm-control">
								</div>
							</div>
							</div>
							
							
							<div class="ucFrom-row" style="margin-bottom: 20px;" v-if="fwht">
							<div class="ucForm-group-ad">		
								<div class="ucForm-group">
									<label class="ucForm-label">建设单位</label>
									<input name="wholesalePrice" type="text" v-model="jsdw" :disabled="true" class="ucForm-control">
								</div>
							</div>
							<div class="ucForm-group-ad">		
								<div class="ucForm-group">
									<label class="ucForm-label">联系人</label>
									<input name="wholesalePrice" type="text" v-model="lxr" :disabled="true" class="ucForm-control">
								</div>
							</div>
							<div class="ucForm-group-ad">		
								<div class="ucForm-group">
									<label class="ucForm-label">联系电话</label>
									<input name="wholesalePrice" type="text" v-model="lxdh" :disabled="true" class="ucForm-control">
								</div>
							</div>
							<div class="ucForm-group-ad auto-d100">		
								<div class="ucForm-group">
									<label class="ucForm-label">建设单位地址</label>
									<input name="wholesalePrice" type="text" v-model="jsdwdz" :disabled="true" class="ucForm-control">
								</div>
							</div>
							</div>
							<div class="ucFrom-row" v-if="fwht">
								<div class="ucFrom-row">
									<div class="ucForm-group-ad">		
										<div class="ucForm-group">
											<label class="ucForm-label">是否是框架协议</label>
											<select v-model="isframe" class="ucForm-control" :disabled="true">
												<option v-for="isframe in isframes" v-bind:key="isframe.value" :value="isframe.value">
													{{isframe.isframe}}
												</option>
											</select>
										</div>
									</div>
									<div class="ucForm-group-ad">		
										<div class="ucForm-group">
											<label class="ucForm-label">项目来源</label>
											<input name="wholesalePrice" type="text" v-model="xmly" :disabled="true" class="ucForm-control">
										</div>
									</div>
									<div class="ucForm-group-ad">		
										<div class="ucForm-group">
											<label class="ucForm-label">是否联合体</label>
											<select v-model="sflht" class="ucForm-control" :disabled="true">
												<option v-for="sflht in sflhts" v-bind:key="sflht.value" :value="sflht.value">
													{{sflht.sflht}}
												</option>
											</select>
										</div>
									</div>
								</div>
								<div class="ucForm-group-ad">	
									<div class="ucForm-group">
										<label class="ucForm-label">联合体单位</label>
										<input name="wholesalePrice" type="text" v-model="lhtdw" :disabled="true" class="ucForm-control">
									</div>
								</div>
								<div class="ucForm-group-ad">		
									<div class="ucForm-group">
										<label class="ucForm-label">联系人</label>
										<input name="wholesalePrice" type="text" v-model="lhtlxr" :disabled="true" class="ucForm-control">
									</div>
								</div>
								<div class="ucForm-group-ad">		
									<div class="ucForm-group">
										<label class="ucForm-label">联系电话</label>
										<input name="wholesalePrice" type="text" v-model="lhtlxdh" :disabled="true" class="ucForm-control">
									</div>
								</div>
								<div class="ucForm-group-ad auto-d100">		
									<div class="ucForm-group">
										<label class="ucForm-label">联合体单位地址</label>
										<input name="wholesalePrice" type="text" v-model="lhtdwdz" :disabled="true" class="ucForm-control">
									</div>
								</div>
								<div class="ucForm-group-ad auto-d100">		
									<div class="ucForm-group">
										<label class="ucForm-label">内容备注</label>
										<textarea name="wholesalePrice" type="text" v-model="mark" :disabled="true" class="ucForm-control"></textarea>
									</div>
								</div>
							</div>
							<div class="ucForm-group-ad" v-if="cght">
								<div class="ucForm-group" >
									<label class="ucForm-label">供应商名称</label>
									<input name="wholesalePrice" type="text" v-model="gysmc" :disabled="true" class="ucForm-control">
								</div>
							</div>
							<div class="ucForm-group-ad" v-if="cght">
								<div class="ucForm-group" >
									<label class="ucForm-label">资质等级</label>
									<input name="wholesalePrice" type="text" v-model="gyszzdj" :disabled="true" class="ucForm-control">
								</div>
							</div>
							<div class="ucForm-group-ad" v-if="cght">
								<div class="ucForm-group" >
									<label class="ucForm-label">主体合同名称</label>
									<input name="wholesalePrice" type="text" v-model="ztcname" :disabled="true" class="ucForm-control">
								</div>
							</div>
							<div class="ucForm-group-ad" v-if="cght">
								<div class="ucForm-group">
									<label class="ucForm-label">主体合同编号</label>
									<input name="wholesalePrice" type="text" v-model="zthtId" :disabled="true" class="ucForm-control">
								</div>
							</div>
							<div class="ucForm-group-ad" v-if="cght">
								<div class="ucForm-group" >
									<label class="ucForm-label" >主体合同金额/元</label>
									<input name="wholesalePrice" type="text" v-model="zthtAmount" :disabled="true" class="ucForm-control">
								</div>
							</div>
							<div class="ucForm-group-ad auto-d100" v-if="cght">
								<div class="ucForm-group" >
									<label class="ucForm-label" >采购内容</label>
									<textarea name="wholesalePrice" type="text" v-model="cgnr" :disabled="true" class="ucForm-control"></textarea>
								</div>
							</div>
							<div class="ucForm-group-ad auto-d100" v-if="cght">
								<div class="ucForm-group" >
									<label class="ucForm-label" >技术要求</label>
									<textarea name="wholesalePrice" type="text" v-model="jsyq" :disabled="true" class="ucForm-control"></textarea>
								</div>
							</div>
							
							<div class="ucForm-group-ad " v-if="fwht">
								<div class="ucForm-group">
									<label class="ucForm-label">工程进度</label>
									<input name="wholesalePrice" type="text" v-model="gcjd" class="ucForm-control">
								</div>
							</div>
							
							<div class="ucForm-group-ad auto-d100" v-if="fwht">		
								<div class="ucForm-group" style=" padding-right: 32px; padding-top: 15px;">
									<label class="ucForm-label" style="top:12px;">工程进度</label>
									<el-progress :percentage="percentage" :stroke-width="12" :text-inside="true"></el-progress>
									<div class="ctms_iconQuest">
										<div class="ctms_questShow">
											<i class="iArr"></i>
											<div class="ctms_questShowM">
												<div class="ctms_questTableDiv">
												<table class="ctms_questTable" >
													<tbody >
														<tr v-for="progress2 in progress" :key="progress2.user">
															<td>{{progress2.user}}</td>
															<td>{{progress2.createTime}}</td>
															<td><span class="spanArr" v-if="progress2.stat==0">{{progress2.gcjd}}</span>
															<span class="spanArr arrUp" v-if="progress2.stat==1">{{progress2.gcjd}}</span></td>
														</tr>
													</tbody>
												</table>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							
							<div v-if="viewOnly == false" class="ucForm-group-ad ucForm-group-btn auto-d100">		
								
									<div class="ucForm-group ucBtn-bg">
										<button class="ucBtn ucBtn-ok ucBtn-green" v-on:click="commit"><i class="iImg"></i>保存</button>
										<button class="ucBtn ucBtn-refresh ucBtn-gray" v-on:click="closecallinfo"><i class="iImg"></i>清空</button>
										<!-- <button class="ucBtn ucBtn-close ucBtn-gray" v-on:click="closecallinfo"><i class="iImg"></i>关闭</button> -->
									</div>
								
							</div>
							<div class="clear"></div>
						</div>
					</div>
				</el-tab-pane>
			    <!-- <el-tab-pane label="&nbsp;&nbsp;&nbsp;&nbsp;采购记录&nbsp;&nbsp;&nbsp;&nbsp;" name="second" v-if="fwht">
					<div class="ctms_dataStaFlow">
						<ul>
							<li>采购合计金额：<b class="fontOrange">{{total}}</b>万元</li>
							<li>采购比例：<b class="fontBlue">{{proportion}}</b></li>
						</ul>
					</div>
					<div class="ucPanel ucPanel-table">
						
							<el-table :data="dataList" highlight-current-row border style="width: 100%">
								<el-table-column prop="id" label="序号"  width="78px;"></el-table-column>
								<el-table-column prop="gysmc" label="承接单位" ></el-table-column>
								<el-table-column prop="purchaseAmount" label="合同金额" ></el-table-column>
								<el-table-column prop="signDate" label="采购日期"></el-table-column>
								<el-table-column fixed="right" label="操作" width="180px" >
									<template v-slot="scope" >
										<button class="ucBtn ucBtn-info ucBtn-green" @click="handleClickDetail(scope.row)"><i class="iImg"></i>详情</button>
									</template>
								</el-table-column>
							</el-table>
						
					</div>
				</el-tab-pane> -->
			    <el-tab-pane label="&nbsp;&nbsp;&nbsp;&nbsp;财务数据&nbsp;&nbsp;&nbsp;&nbsp;" name="third" >
					<div class="ctms_dataStaFlow">
						<ul>
							<li>收款合计金额：<b class="fontOrange">{{financialTotal}}</b>万元</li>
						</ul>
					</div>
					<div class="ucPanel ucPanel-table" >
						
							<el-table :data="dataList2" highlight-current-row border style="width: 100%">
								<el-table-column prop="id" label="序号"  width="78px;"></el-table-column>
								<el-table-column prop="financialAmount" label="收款金额/元" ></el-table-column>
								<el-table-column prop="paydate" label="收款时间"></el-table-column>
								<el-table-column prop="user" label="操作人" ></el-table-column>
								<el-table-column prop="mark" label="备注" ></el-table-column>
								<el-table-column fixed="right" label="操作" width="180px" >
									<template v-slot="scope" >
										<button class="ucBtn ucBtn-info ucBtn-green" @click="handleClickEdit(scope.row)"><i class="iImg"></i>详情</button>
									</template>
								</el-table-column>
							</el-table>
						
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>	
			
	</el-dialog>
	</div>
</template>
<script>
	import {ElConfigProvider} from 'element-plus';
	import zhCn from 'element-plus/lib/locale/lang/zh-cn';
	export default {
		name: 'ZhProvider',
		components:{
			[ElConfigProvider.name]: ElConfigProvider
		},
		setup(){
			let locale = zhCn
			return{
				locale
			}
		},
		data: function() {
			return {
				viewOnly: false,
				centerDialogVisiblep: false,
				id: this.$parent.editId,
				ctype: '',
				zthtId:this.$parent.zthtId,
				gid:this.$parent.gid,
				mod: this.$parent.infoMod,
				editDisable: false,
				name:"",
				selected: '',
				cno:'',
				// ctype:'',
				cname:'',
				department:'',
				signDate:'',
				applyDate:'',
				amount:'',
				xmlb:'',
				zy:'',
				xmfzr:'',
				xmdd:'',
				gctz:'',
				jsdw:'',
				lxr:'',
				lxdh:'',
				jsdwdz:'',
				xmly:'',
				// sflht:'',
				lhtdw:'',
				lhtlxr:'',
				lhtdh:'',
				lhtdwdz:'',
				mark:'',
				gcjd:'',
				total:'',
				proportion:'',
				purchaseAmount:'',
				financialAmount:'',
				financialTotal:'',
				user:'',
				paydate:'',
				progress:[],
				ctypes: [
					{ ctype: '服务合同', value: '1' },
					{ ctype: '采购合同', value: '2' }
				],
				major:null,
				zys:[],
				xmlbs:[],
				departments:[],
				// isframe:'',
				isframes:[
					{isframe:"是",value:'1'},
					{isframe:"否",value:'2'}
				],
				isawards:[
					{isaward:"是",value:'1'},
					{isaward:"否",value:'2'}
				],
				stats:[
					{stat: '正常', value: '1' },
					{stat: '作废', value: '2' }
				],
				sflhts: [
					{ sflht: '是', value: '1' },
					{ sflht: '否', value: '2' }
				],
				gyszzdj:'',
				gysdz:'',
				gysmc:'',
				ztcname:'',
				zthtAmount:'',
				cgnr:'',
				jsyq:'',
				percentage:'',
				// total:'',
				activeName: 'first',
				dataList:[],
				dataList2:[],
			}
		},
		created() {
			this.centerDialogVisiblep = true;
			this.searchMajors();
			this.searchDepartment();
			this.getType();
			// this.searchPurchaseRecord();
			this.htlx();	
			this.searchFinancialRecord();
			this.searchAward();
			this.searchUpdateList();
			this.fetch();
		},
		methods: {
			handleClickEdit: function(data){
				var req = {};
				this.infoMod = "edit";
				// req.cmd = "searchDetail";
				// this.editId = data.id;
				// this.ctype = data.ctype;
				// this.cno = data.cno;
				this.editId = data.id;
				console.log(this.editId);
				this.centerDialogVisiblef = true;
			},
			handleClickDetailfunction(data){
				var req = {};
				this.infoMod = "edit";
				// req.cmd = "searchDetail";
				// this.editId = data.id;
				// this.ctype = data.ctype;
				// this.cno = data.cno;
				this.editId = data.id;
				console.log(this.editId);
				this.centerDialogVisible = true;
			},
			commit: function() {
				var self = this;
				var req = {};
					req.cmd = "updateGCJD";
					req.id = this.id;
					req.gcjd = this.gcjd;
				this.$sknet.postJson(this.$skconf("wyvc.surl"), req, function(res) {
					self.$message("操作成功");
					self.closecallinfo();
				}, null, self);
			},
			fetch: function() {
				var req = {};
				req.cmd = "searchDetail";
				// req.id = this.id;
				// req.ctype =this.ctype;
				req.id = this.$parent.editId;
				req.ctype =this.$parent.ctype;
				console.log(this.$parent.editId)
				if (this.mod == 'add') {					return;
				}
				this.$sknet.postJson(this.$skconf("wyvc.surl"), req, function(res) {
					console.log(res);
					var item = res.datas.result;
					this.gid = item.gid;
					this.cno = item.cno;
					this.ctype = item.ctype;
					this.cname = item.cname;
					this.department = item.gid;
					this.signDate = item.signDate;
					this.applyDate = item.applyDate;
					this.amount = item.amount;
					this.xmlb = item.xmlb;
					this.zy = item.zy;
					this.xmfzr = item.xmfzr;
					this.xmdd = item.xmdd;
					this.gctz = item.gctz;
					this.jsdw = item.jsdw;
					this.lxr = item.lxr;
					this.lxdh = item.lxdh;
					this.jsdwdz = item.jsdwz;
					this.xmly = item.xmly;
					this.sflht = item.sflht;
					this.lhtdw = item.lhtdw;
					this.lhtlxr = item.lhtlxr;
					this.lhtdh = item.lhtdh;
					this.lhtdwdz = item.lhtdwdz;
					this.mark = item.mark;
					this.gcjd = item.gcjd;
					this.gyszzdj = item.gyszzdj;
					this.ztcname = item.ztname;
					this.zthtId = item.zthtId;
					this.jsyq = item.jsyq;
					this.cgnr = item.cgnr;
					this.isframe =item.isframe;
					this.zthtAmount = item.zthtAmount;
					// console.log(this.$parent.editId)
				}, null, this);
			},
			closecallinfo: function() {
				this.centerDialogVisiblep = true;
				this.centerDialogVisiblef = false;
				this.$emit('closecallinfop'); //通知父组件改变。
			},
			htlx:function() {
				console.log(this.ctype)
				if(this.ctype==1){
					this.fwht=true;
					this.cght=false;
				}else{
					this.fwht=false;
					this.cght=true;
				}
			},
			// searchPurchaseRecord:function(){
			// 	this.dataList = [];
			// 		var req = {};
			// 		req.cmd = "searchPurchaseRecord";
			// 		if(this.ctype==1){
			// 			req.id = this.id;
			// 		}else{
			// 			req.zthtId = this.zthtId;
			// 		}
			// 		req.gid = this.gid;
			// 		this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
			// 			console.log(res.datas.result.contract);
			// 			console.log(res.datas.contract);
			// 			var item = res.datas.result.contract;
			// 			this.dataList=item;
			// 			this.total =res.datas.contract.total;
			// 			this.proportion = res.datas.contract.proportion;
			// 		}, null, this);	
			// 	},
			searchFinancialRecord:function(){
				this.dataList2 = [];
					var req = {};
					req.cmd = "searchFinancialRecord";
					if(this.ctype==1){
						req.id = this.id;
						req.zthtId = this.zthtId;
					}else{
						req.id = this.id
					}
					req.ctype = this.ctype;
					this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
						console.log(res.datas.result.cFinancials);
						console.log(res.datas.contract.contract);
						this.dataList2=res.datas.result.cFinancials;
						this.financialTotal = res.datas.contract.financialTotal;
					}, null, this);	
				},
			searchAward:function(){
				this.dataList3 = [];
					var req = {};
					req.cmd = "searchAward";
					req.id = this.id;
					this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
						console.log(res.datas.result);
						this.dataList3=res.datas.result;
					}, null, this);	
				},
			searchMajors: function(){
				this.zys = [];
				var req = {};
				req.cmd = "searchMajors";
				// req.id = id;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					console.log(res.datas.result);
					this.zys = res.datas.result.majors;
					console.log(this.zy);
				}, null, this);	
			},
			searchDepartment:function(){
				this.departments = [];
				var req = {};
				req.cmd = "searchDepartment";
				req.type=2;
				// req.id = id;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					console.log(res.datas.result.departments);
					this.departments = res.datas.result.departments;
				}, null, this);	
			},
			getType:function(){
				this.xmlbs = [];
				var req = {};
				req.cmd = "getType";
				// req.id = id;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					console.log(res.datas.result.majors);
					this.xmlbs = res.datas.result.majors;
					console.log(this.xmlbs);
				}, null, this);	
			},
			searchUpdateList:function(){
				this.progress = [];
				var req = {};
				req.cmd = "searchUpdateList";
				req.id = this.id
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					console.log(res.datas.result);
					this.progress = res.datas.result;
					console.log(this.progress.length);
					if(res.datas.result[this.progress.length-1].gcjd==0){
						return;
					}else{
						this.percentage = res.datas.result[this.progress.length-1].gcjd;
					}
					
				}, null, this);	
			}
		},
		mounted() {
			
			
		},
		components: {
			
		}
	}
</script>

<style scoped>

</style>
