<template>
	<el-dialog title="财务详情" v-model="centerDialogVisiblef" width="40%" center :close-on-click-modal = "false" @close='closecallinfo'>
			<div class="ucPanel" style="height: 668px;">
				<div class="ucPanel bgGrayBox">
					<div class="ucForm ucForm-lump auto-d3 nameLong" >
						<div class="ucFrom-row" style="margin-bottom: 20px;">

						<div class="ucForm-group-ad">		
							<div class="ucForm-group">
								<label class="ucForm-label">首付款类型</label>
								<input name="wholesalePrice" type="text" v-model="ftype" :disabled="true" class="ucForm-control">
							</div>
						</div>
						<div class="ucForm-group-ad">		
							<div class="ucForm-group">
								<label class="ucForm-label">合同主表ID</label>
								<input name="wholesalePrice" type="text" v-model="cid" :disabled="true" class="ucForm-control">
							</div>
						</div>
						<div class="ucForm-group-ad">			
							<div class="ucForm-group">
								<label class="ucForm-label">金额/元</label>
								<input name="wholesalePrice" type="text" v-model="amount" :disabled="true" class="ucForm-control">
							</div>
						</div>
						<div class="ucForm-group-ad">		
							<div class="ucForm-group">
								<label class="ucForm-label">首付款日期</label>
								<input name="wholesalePrice" type="text" v-model="paydate" :disabled="true" class="ucForm-control">
							</div>
						</div>
						<div class="ucForm-group-ad">		
							<div class="ucForm-group">
								<label class="ucForm-label">操作人</label>
								<input name="wholesalePrice" type="text" v-model="user" :disabled="true" class="ucForm-control">
							</div>
						</div>
						</div>
								<!-- <div class="ucForm-group ucBtn-bg">
									<button class="ucBtn ucBtn-ok ucBtn-green" v-on:click="commit"><i class="iImg"></i>保存</button>
									<button class="ucBtn ucBtn-refresh ucBtn-gray" v-on:click="closecallinfo"><i class="iImg"></i>清空</button> -->
									<!-- <button class="ucBtn ucBtn-close ucBtn-gray" v-on:click="closecallinfo"><i class="iImg"></i>关闭</button> -->
								<!-- </div> -->
							
						</div>
						<div class="clear"></div>
					</div>
				</div>
				
			
	</el-dialog>
</template>

<script>
	import {ElConfigProvider} from 'element-plus';
	import zhCn from 'element-plus/lib/locale/lang/zh-cn';
	export default {
		name: 'ZhProvider',
		components:{
			[ElConfigProvider.name]: ElConfigProvider
		},
		setup(){
			let locale = zhCn
			return{
				locale
			}
		},
		data: function() {
			return {
				viewOnly: false,
				centerDialogVisiblef: false,
				id: this.$parent.editId,
				mod: this.$parent.infoMod,
				editDisable: false,
				name:"",
				ftype:'',
				cid:'',
				amount:'',
				paydate:'',
				user:'',
				mark:'',
				dataList:[],
				
			}
		},
		created() {
			this.centerDialogVisiblef = true;
			this.fetch();
		},
		methods: {
			commit: function() {
				var self = this;
				var req = {};
				if (this.mod == 'edit') {
					req.cmd = "";
					req.id = self.id;
				} else {
					req.cmd = "";
				}
			
				this.$sknet.postJson(this.$skconf("wyvc.surl"), req, function(res) {
					self.$message("操作成功");
					self.closecallinfo();
				}, null, self);
			},
			fetch: function() {
				var datalist = [];
				var req = {};
				req.cmd = 'searchFinancialRecordDetail';
				req.id = this.id;
				req.ctype = this.ctype;
				if (this.mod == 'add') {					return;
				}
				this.$sknet.postJson(this.$skconf("wyvc.surl"), req, function(res) {
					console.log(res)
					var item=res.datas.result;
					this.ftype=item.ftype;
					this.cid=item.cid;
					this.amount=item.amount;
					this.paydate=item.paydate;
					this.user=item.user;
					this.mark=item.mark;
				}, null, this);
			},
			closecallinfo: function() {
				this.centerDialogVisiblef = true;
				this.$emit('closecallinfof'); //通知父组件改变。
				// this.$emit('closecallinfop');
			},
		},
		mounted() {
			if (this.mod == 'add') {
				this.viewOnly = false;
			}
			//设置 viewOnly
			if (this.mod == 'edit') {
				this.viewOnly = false;
				// this.fetch();
			}
		},
		components: {
			
		}
	}
	
</script>

<style scoped>

</style>
