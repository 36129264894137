<template>
	<el-dialog title="工程进度调整" v-model="centerDialogVisibleg" width="30%" center :close-on-click-modal = "false" @close='closecallinfo'>
		
			<el-tabs v-model="activeName" @tab-click="handleClick" >
			   <!-- <el-tab-pane label="&nbsp;&nbsp;&nbsp;&nbsp;工程进度调整&nbsp;&nbsp;&nbsp;&nbsp;" name="first" > -->

						<div class="ucForm ucForm-lump auto-d3 nameLong" >
							<div class="ucFrom-row" style="margin-bottom: 20px;">
							<div class="ucForm-group-ad">		
								<div class="ucForm-group">
									<label class="ucForm-label">工程进度</label>
									<el-input-number name="wholesalePrice" v-model="gcjd"  placeholder="请输入0-100得整数"
										:min = "0" :max = "100" :precision = "0" size="large" >
									</el-input-number>
								</div>
							</div>
							<div class="ucForm-group-ad">		
								<div class="ucForm-group">
									<label class="ucForm-label">调整时间</label>
									<el-config-provider :locale="locale">
									<el-date-picker v-model="createTime" type="date" value-format="YYYY-MM-DD"></el-date-picker>
									</el-config-provider>
								</div>
							</div>
							<div class="clear"></div>
						</div>
					</div>
					
				<!-- </el-tab-pane> -->
			   <div class="ucForm-group ucBtn-bg">
				<div class="ucFrom-row" style="margin-bottom: 20px;">
			   	<button class="ucBtn ucBtn-ok ucBtn-green" v-on:click="commit"><i class="iImg"></i>保存</button>
			   	<button class="ucBtn ucBtn-refresh ucBtn-gray" v-on:click="closecallinfo"><i class="iImg"></i>清空</button>
			   	<!-- <button class="ucBtn ucBtn-close ucBtn-gray" v-on:click="closecallinfo"><i class="iImg"></i>关闭</button> -->
				</div>
			   </div>
			    
				
			</el-tabs>
		
			
	</el-dialog>
</template>
<script>
	import {ElConfigProvider} from 'element-plus';
	import zhCn from 'element-plus/lib/locale/lang/zh-cn';
	export default {
		name: 'ZhProvider',
		components:{
			[ElConfigProvider.name]: ElConfigProvider
		},
		setup(){
			let locale = zhCn
			return{
				locale
			}
		},
		data: function() {
			return {
				viewOnly: false,
				centerDialogVisibleg: false,
				id: this.$parent.editId,
				mod: this.$parent.infoMod,
				editDisable: false,
				gcjd:null,
				createTime:null,
				
			}
		},
		created() {
			this.centerDialogVisibleg = true;
		},
		methods: {
			commit: function() {
				var self = this;
				var req = {};
					req.cmd = "updateGCJD";
					req.id = this.id;
					req.createTime = this.createTime;
					req.gcjd = this.gcjd;
					
					if (self.createTime == undefined || self.createTime == null || self.createTime == "") {
						self.$message("请选择调整时间！");
						return;
					}
					
				this.$sknet.postJson(this.$skconf("wyvc.surl"), req, function(res) {
					console.log(res)
					if(res.result=="NG"){
						self.$message({message:"操作失败",type:'error'});
					}else {
						if(res.datas.sf=="1"){
							self.$message({message:"不可降低进度",type:'error'});
						}else if(res.datas.sf=="2"){
							self.$message({message:"时间不能早于上次",type:'error'});
						}else if(res.datas.sf=="3"){
							self.$message({message:"没有修改权限",type:'error'});
						}else{
							self.$message({message:"操作成功",type:'success'});
						}
					}
					
					self.closecallinfo();
				}, null, self);
			},
			fetch: function() {
				
			},
			closecallinfo: function() {
				this.centerDialogVisibleg = false;
				this.$emit('closecallinfog'); //通知父组件改变。
				// this.$emit('closecallinfop');
			},
		},
		mounted() {
			if (this.mod == 'add') {
				this.viewOnly = false;
			}
			//设置 viewOnly
			if (this.mod == 'edit') {
				this.viewOnly = false;
				// this.fetch();
			}
		},
		components: {
			
		}
	}
	
</script>

<style scoped>

</style>